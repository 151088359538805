import { TSlot } from '../types';

export interface ICancelationDetails {
  canCancel: boolean;
  message: string;
}

export interface IScheduleActive {
  id: string;
  schedule_date: string;
  type_service: string;
  schedule_time: TSlot;
  cancelationDetails: ICancelationDetails;
}

export interface IServiceType {
  id: string;
  serviceType: string;
  label: string;
  operation: string;
}

export interface IAccountContractResult {
  ownAccount: string;
  address: string;
  phone: string;
  account: string;
  city: string;
  vantimaxCoverage: boolean;
  scheduleActive: IScheduleActive[];
  bag: string;
  sameDaySlot: string;
  scheduleDate: string;
  segmentedZone: string;
  zone: string;
  serviceTypes: IServiceType[];
}

export interface IAccountContractResponseData {
  errorCode?: number;
  message: string;
  status: 'SUCCESS' | 'ERROR';
  data: IAccountContractResult;
}
