import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { catchError, firstValueFrom, map, of, take } from 'rxjs';

import { AuthUser } from '@core/domain/entities';
import { environment } from '@env/environment';
import {
  createMenuModules,
  mapModulesPermissions,
} from '@core/domain/builders';
import { StorageService } from '@core/shared/services';
import { AuthService } from '@core/modules/auth/services/auth.service';
import { ResponseMap } from '@core/domain/helpers';

type TUserState = {
  token: string;
  xUser: string;
  isFetching: boolean;
};

const initialState: TUserState = {
  token: '',
  xUser: '',
  isFetching: false,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withHooks((store) => {
    const storage = inject(StorageService);

    return {
      onInit: async () => {
        const token = await storage.getItem('token');
        patchState(store, { token });
      },
    };
  }),
  withComputed(({ token }) => ({
    authUser: computed(() => {
      return AuthUser.fromToken(token());
    }),
    isAuthActive: computed(() => {
      const user = AuthUser.fromToken(token());

      if (!user.iat) {
        return false;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      const limitInSeconds = environment.limitTokenInHours * 60 * 60;
      const isExpiredToken = currentTime - user!.iat > limitInSeconds;
      const isUserActive = [!!token(), !isExpiredToken].every(Boolean);

      return isUserActive;
    }),
    permissions: computed(() => {
      if (!token()) {
        return null;
      }

      const user = AuthUser.fromToken(token());

      return mapModulesPermissions(user.modules);
    }),
    getMenuModules: computed(() => {
      const user = AuthUser.fromToken(token());

      return createMenuModules(user.modules);
    }),
  })),
  withMethods((store) => {
    const storage = inject(StorageService);
    const authService = inject(AuthService);

    return {
      onClear: async () => {
        await storage.clear();
        patchState(store, initialState);
      },
      onSetToken: async (token: string) => {
        await storage.setItem('token', token);
        patchState(store, { token });
      },
      doSetXUser: (xUser: string) => {
        patchState(store, { xUser });
      },
      getUrlAuth: async (captcha: string, action: string) => {
        patchState(store, { isFetching: true });

        const resp = await firstValueFrom(
          authService.getAuthUrl(captcha, action).pipe(
            map(ResponseMap.success),
            catchError((err) => of(ResponseMap.failed(err))),
          ),
        );

        patchState(store, { isFetching: false });

        return resp;
      },
      doSignOut: async () => {
        const resp = firstValueFrom(
          authService.logout().pipe(
            take(1),
            map(ResponseMap.success),
            catchError((err) => of(ResponseMap.failed(err))),
          ),
        );

        await storage.clear();
        patchState(store, initialState);

        return resp;
      },
      getUserToken: async (token: string) => {
        patchState(store, { isFetching: true });
        const resp = await firstValueFrom(
          authService.validateToken(token).pipe(
            map(ResponseMap.success),
            catchError((err) => of(ResponseMap.failed(err))),
          ),
        );

        patchState(store, { isFetching: false });

        if (resp.success) {
          await storage.setItem('token', resp.data);
          patchState(store, { token: resp.data });
        }

        return resp;
      },
    };
  }),
);
