import { DateTime, Settings } from 'luxon';

Settings.defaultZone = 'America/Bogota';

export class DateTimeAdapter {
  static now() {
    return DateTime.now();
  }

  static customYear() {
    return DateTime.now().year;
  }

  static customMonth() {
    return DateTime.now().month;
  }

  static nextYear(date: number, day: number = 0) {
    return DateTime.fromMillis(date)
      .plus({ year: 1 })
      .minus({ day })
      .toMillis();
  }

  static fromMili(date: number) {
    return DateTime.fromMillis(date).toFormat('yyyy-MM-dd');
  }

  static fromISO(date: string) {
    return DateTime.fromISO(date);
  }

  static lastDayMonth(date: string) {
    return DateTime.fromISO(date).endOf('month');
  }

  static toStartDate(date: string): Date {
    return DateTime.fromISO(date, { zone: 'utc' }).startOf('month').toJSDate();
  }

  static toEndDate(date: string): Date {
    return DateTime.fromISO(date, { zone: 'utc' }).endOf('month').toJSDate();
  }

  static toStringDate(date: Date): string {
    return DateTime.fromJSDate(date, { zone: 'utc' }).toSQLDate();
  }

  static toDate(date: string): Date {
    return DateTime.fromISO(date, { zone: 'utc' }).toJSDate();
  }

  static isGreatherThanToday(date: string) {
    const currentDate = DateTime.fromISO(DateTime.now().toISO(), {
      zone: 'utc',
    }).startOf('day');
    const inputDate = DateTime.fromISO(date, { zone: 'utc' }).startOf('day');

    return inputDate >= currentDate;
  }
}
