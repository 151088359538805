import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  map,
  catchError,
  of,
  firstValueFrom,
  switchMap,
  throwError,
} from 'rxjs';

import {
  IBagCapabilities,
  ICapabilitiesDays,
  ICapabilitiesDetailData,
  ISegmentedZoneCapability,
  IUpdateCapabilitiesDetailFormData,
  IUpdateSegmentedZoneCapabilities,
  TUpdateCapabilitiesByDay,
} from '@core/modules/capacities/data/types';
import { CapabilitiesService } from '@core/modules/capacities/services';
import { environment } from '@env/environment';
import { DateTimeAdapter } from '@core/domain/adapters';
import { CapabilityDay } from '@core/modules/capacities/data/entities';
import { ResponseMap, toTitleCase } from '@core/domain/helpers';
import {
  TCapabilitiesFilters,
  TCapabilitiesState,
  TBagsCapabilitiesFilters,
} from './capabilities-store.types';
import { MONTHS } from '@core/shared/constants';

const initFilters: TCapabilitiesFilters = {
  zone: environment.capabilitiesFilters.zoneId,
  operation: environment.capabilitiesFilters.operationId,
  date: DateTimeAdapter.now(),
};

const initialState: TCapabilitiesState = {
  filters: {
    zone: '',
    operation: '',
    date: DateTimeAdapter.now(),
  },
  segmentedZoneId: '',
  monthlyCapabilities: [],
  segmentedCapabilities: [],
  bagsCapabilities: [],
  isFetching: false,
};

export const CapabilitiesStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  // Computed options
  withComputed((store) => ({
    capabilitiesFilial$: computed(() => {
      const filial = store
        .segmentedCapabilities()
        ?.find((sc) => sc.capability_type === 'FILIAL');

      return CapabilityDay.fromJson(filial);
    }),
    capabilitiesList$: computed(() => {
      return store
        .segmentedCapabilities()
        .filter((sc) => sc.capability_type !== 'FILIAL')
        .map(CapabilityDay.fromJson);
    }),
    segmentedZonesOptions$: computed(() => {
      return store
        .segmentedCapabilities()
        .filter((sz) => sz.capability_type !== 'FILIAL')
        .map((sz) => ({
          value: sz.segmented_zone_id,
          label: toTitleCase(sz.segmented_zone_name),
        }));
    }),
    selectedSegmentedZone$: computed(() => {
      return store
        .segmentedCapabilities()
        .find((sc) => sc.segmented_zone_id === store.segmentedZoneId());
    }),
    dateLabel$: computed(() => {
      const date = store.filters().date;
      if (!date) {
        return '';
      }

      return `${date?.day} de ${MONTHS.at(date?.month)}, ${date?.year}`;
    }),
  })),

  // Methods
  withMethods((store, capService = inject(CapabilitiesService)) => ({
    initFilters: () => {
      patchState(store, { filters: initFilters });
    },
    addSegmentedCapabilities: (segmentedCapabilities: ICapabilitiesDays[]) => {
      patchState(store, { segmentedCapabilities });
    },
    getInitFilters: () => initFilters,
    setFilters: (filter: Partial<TCapabilitiesFilters>) => {
      patchState(store, ({ filters }) => ({
        filters: { ...filters, ...filter },
      }));
    },
    setSegmentedZoneId: (segmentedZoneId: string) => {
      patchState(store, { segmentedZoneId });
    },
    // END setSegmentedZoneId

    getChartCapabilities: async (filters: TCapabilitiesFilters) => {
      const formData = {
        ...filters,
        date: filters.date.toFormat('yyyy-MM-dd'),
      };

      patchState(store, { isFetching: true });
      const resp = await firstValueFrom(
        capService.getChartCapabilities(formData).pipe(
          map((data) => ResponseMap.success(data)),
          catchError((err) =>
            of(ResponseMap.failed(err, [] as IBagCapabilities[])),
          ),
        ),
      );

      patchState(store, {
        monthlyCapabilities: resp.data,
        filters,
        isFetching: false,
      });

      return resp;
    },
    // END getChartCapabilities

    getSegmentedZoneCapabilities: async (filters: TCapabilitiesFilters) => {
      const date = filters.date.toFormat('yyyy-MM-dd');
      const formData = {
        ...filters,
        date,
      };

      patchState(store, { isFetching: true });

      const resp = await firstValueFrom(
        capService.getCapabilitiesViewDataByZoneId(formData).pipe(
          switchMap((resp) => {
            if (!resp?.length) {
              return throwError(
                () => new Error('No se encontraron capacidades'),
              );
            }

            const segmentedZoneId = resp.find(
              (sz) => sz.capability_type !== 'FILIAL',
            )?.segmented_zone_id;

            return capService
              .getBagsCapabilitiesBySegmentedZone({
                id: segmentedZoneId,
                operation: filters.operation,
                date,
              })
              .pipe(
                map((bags) => ({
                  segmentedCapabilities: resp,
                  bagsCapabilities: bags,
                  segmentedZoneId,
                })),
              );
          }),
          map((data) => ResponseMap.success(data)),
          catchError((err) =>
            of(
              ResponseMap.failed(err, {
                segmentedCapabilities: [] as ICapabilitiesDays[],
                bagsCapabilities: [] as IBagCapabilities[],
                segmentedZoneId: '',
              }),
            ),
          ),
        ),
      );

      if (!resp.success) {
        patchState(store, {
          ...resp.data,
          isFetching: false,
        });
        return resp;
      }

      patchState(store, {
        ...resp.data,
        filters,
        isFetching: false,
      });
      return resp;
    },
    // END getSegmentedZoneCapabilities

    getBagsCapabilities: async (filters: TBagsCapabilitiesFilters) => {
      const date = filters.date.toFormat('yyyy-MM-dd');

      patchState(store, { isFetching: true });

      const resp = await firstValueFrom(
        capService
          .getBagsCapabilitiesBySegmentedZone({
            id: filters.segmentedZoneId,
            operation: filters.operation,
            date,
          })
          .pipe(
            map((data) => ResponseMap.success(data)),
            catchError((err) =>
              of(ResponseMap.failed(err, [] as IBagCapabilities[])),
            ),
          ),
      );

      if (!resp.success) {
        patchState(store, {
          segmentedZoneId: '',
          bagsCapabilities: [] as IBagCapabilities[],
          isFetching: false,
        });
        return resp;
      }

      patchState(store, {
        segmentedZoneId: filters.segmentedZoneId,
        bagsCapabilities: resp.data,
        isFetching: false,
      });
      return resp;
    },
    // END getBagsCapabilities

    async updateFilialCapabilities(
      filialId: number,
      formData: TUpdateCapabilitiesByDay,
    ) {
      patchState(store, { isFetching: true });

      const resp = await firstValueFrom(
        capService.updateFilialCapabilities(filialId, formData).pipe(
          map((data) => ResponseMap.success(data)),
          catchError((err) => of(ResponseMap.failed(err))),
        ),
      );

      patchState(store, { isFetching: false });

      return resp;
    },
    // END updateFilialCapabilities

    async updateSegmentedZoneCapabilities(
      capabilities: ISegmentedZoneCapability[],
    ) {
      const body: IUpdateSegmentedZoneCapabilities = {
        segmentedZonesCapabilities: capabilities,
      };

      patchState(store, { isFetching: true });

      const resp = await firstValueFrom(
        capService.updateSegmentedZoneCapabilities(body).pipe(
          map((data) => ResponseMap.success(data)),
          catchError((err) => of(ResponseMap.failed(err))),
        ),
      );

      patchState(store, { isFetching: false });

      return resp;
    },
    // END updateSegmentedZoneCapabilities

    updateCapabilityDetail: async (
      id: number,
      capabilityDetails: ICapabilitiesDetailData[],
    ) => {
      const formData: IUpdateCapabilitiesDetailFormData = { capabilityDetails };

      patchState(store, { isFetching: true });

      const resp = await firstValueFrom(
        capService.updateCapabilitiesDetailBySegmentedZoneId(id, formData).pipe(
          map((data) => ResponseMap.success(data)),
          catchError((err) => of(ResponseMap.failed(err))),
        ),
      );

      patchState(store, { isFetching: false });

      return resp;
    },
    // END updateCapabilityDetail
  })),
);
