import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { take } from 'rxjs';

import {
  ICapabilitiesFiltersBodyData,
  TUpdateCapabilitiesByDay,
  TCapabilitiesByOperation,
  ICapabilitiesDays,
  ICapabilityMonth,
  IBagsCapabilitiesFilters,
  IBagCapabilities,
  IUpdateSegmentedZoneCapabilities,
  IUpdateResponse,
  IUpdateCapabilitiesDetailFormData,
} from '../data/types';
import { TAvailableCapability } from '@core/domain/entities/scheduler/types';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private readonly apiPath = `${environment.apiPath}`;
  private readonly httpClient = inject(HttpClient);

  getChartCapabilities(params: ICapabilitiesFiltersBodyData) {
    return this.httpClient
      .get<ICapabilityMonth[]>(
        `${this.apiPath}/capabilities/filial-capabilities/monthly`,
        {
          params: params as unknown as HttpParams,
          headers: {
            'x-api': 'rest',
          },
        },
      )
      .pipe(take(1));
  }

  getCapabilitiesViewDataByZoneId(filters: ICapabilitiesFiltersBodyData) {
    return this.httpClient
      .get<ICapabilitiesDays[]>(
        `${this.apiPath}/capabilities/segmented-zones/by-day`,
        {
          params: filters as unknown as HttpParams,
          headers: {
            'x-api': 'rest',
          },
        },
      )
      .pipe(take(1));
  }

  getBagsCapabilitiesBySegmentedZone(filters: IBagsCapabilitiesFilters) {
    const { id, date, operation } = filters;
    const url = `${this.apiPath}/capabilities/segmented-zones/${id}/bags/operation/${operation}/by-date/${date}`;
    return this.httpClient
      .get<IBagCapabilities[]>(url, {
        headers: {
          'x-api': 'rest',
        },
      })
      .pipe(take(1));
  }

  updateFilialCapabilities(filialId: number, body: TUpdateCapabilitiesByDay) {
    const url = `${this.apiPath}/capabilities/filial-capabilities/${filialId}`;
    return this.httpClient
      .patch<IUpdateResponse>(url, body, {
        headers: {
          'x-api': 'rest',
        },
      })
      .pipe(take(1));
  }

  updateSegmentedZoneCapabilities(body: IUpdateSegmentedZoneCapabilities) {
    const url = `${this.apiPath}/segmented-zones/capabilities`;
    return this.httpClient
      .patch<IUpdateResponse>(url, body, {
        headers: {
          'x-api': 'rest',
        },
      })
      .pipe(take(1));
  }

  updateCapabilitiesDetailBySegmentedZoneId(
    id: number,
    body: IUpdateCapabilitiesDetailFormData,
  ) {
    const url = `${this.apiPath}/segmented-zones/${id}/capabilities-details`;
    return this.httpClient
      .patch<IUpdateResponse>(url, body, {
        headers: {
          'x-api': 'rest',
        },
      })
      .pipe(take(1));
  }

  getCapabilitiesByOperation(operation: TCapabilitiesByOperation) {
    return this.httpClient
      .post<
        TAvailableCapability[]
      >(`${this.apiPath}/capabilities/capabilities-details/available-capabilities`, operation)
      .pipe(take(1));
  }
}
