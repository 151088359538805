type TSlot = 'AM' | 'PM';

export class CreateSchedule {
  account_contract: string;
  attendant_name: string;
  attendant_phone: string;
  data_policy_accepted: boolean;
  process_accepted: boolean;
  scheduling_name: string;
  scheduling_phone: string;
  service_date: string;
  service_type: string;
  technical_referral: string;
  time_slot: TSlot;
  visitor_data_policy_accepted: boolean;
  will_attend: boolean;
}
