import { gql } from 'apollo-angular';

export const GET_APP_FILTERS_DATA = gql`
  query Query {
    operations: listOperations {
      id
      name
      label
    }
    filials: zoneList {
      id
      code
      name
    }
  }
`;
