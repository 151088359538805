import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { environment } from '@env/environment';

export const provideRecaptchaV3 = () => ({
  provide: RECAPTCHA_V3_SITE_KEY,
  useValue: environment.recaptchaKey,
});

export const provideRecaptchaOptions = () => ({
  provide: RECAPTCHA_LOADER_OPTIONS,
  useValue: {
    onBeforeLoad() {
      const recaptchaEnterpriseUrl = new URL(
        'https://www.google.com/recaptcha/enterprise.js',
      );

      recaptchaEnterpriseUrl.searchParams.set(
        'render',
        environment.recaptchaKey,
      );
      return {
        url: recaptchaEnterpriseUrl,
      };
    },
    onLoaded(recaptcha) {
      return recaptcha.enterprise;
    },
  },
});
