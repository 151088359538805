import { Injectable, inject } from '@angular/core';
import { from, map, switchMap, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { AuthTokenResponse, LoginRedirectResponse } from '../types/auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apiUrl = `${environment.apiPath}/auth`;
  private readonly httpClient = inject(HttpClient);

  getCurrentIp() {
    return from(
      fetch(environment.ipPublicUrl).then((data) => data.json()),
    ).pipe(map(({ ip }) => ip));
  }

  getAuthUrl(captcha: string, action: string) {
    return this.getCurrentIp().pipe(
      switchMap((ip) => {
        return this.httpClient
          .post<LoginRedirectResponse>(
            `${this.apiUrl}/redirect-url`,
            {
              ip,
              referer: environment.callbackUrl,
            },
            {
              headers: {
                'x-recaptcha': captcha,
                'x-action': action,
              },
            },
          )
          .pipe(
            take(1),
            map((res) => res.url),
          );
      }),
    );
  }

  validateToken(token: string) {
    return this.httpClient
      .post<AuthTokenResponse>(`${this.apiUrl}/validate`, { token })
      .pipe(
        take(1),
        map((res) => res.token),
      );
  }

  logout() {
    return this.httpClient
      .post<string>(`${this.apiUrl}/logout`, {})
      .pipe(take(1));
  }

  refreshToken(token: string) {
    return this.httpClient
      .post<AuthTokenResponse>(`${this.apiUrl}/refresh`, {
        token,
      })
      .pipe(
        take(1),
        map((res) => res.token),
      );
  }
}
