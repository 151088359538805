import { DateTime } from 'luxon';

export type TAccountContract = {
  account_contract: string;
  scheduling_name: string;
  scheduling_phone: string;
  technical_referral: string;
  data_policy_accepted: boolean;
  process_accepted: boolean;
  will_attend: boolean;
  attendant_name: string;
  attendant_phone: string;
  visitor_data_policy_accepted: boolean;
};

export type TSearchAccountContract = Omit<TAccountContract, 'will_attend'> & {
  will_attend: 'YES' | 'NO';
};

export type TSlot = 'AM' | 'PM';

export interface TimeSlotsInfo {
  label: string;
  slot: TSlot;
  capacity: number;
}

export type TAvailableCapability = {
  date: string;
  total_capacity: number;
  segmented_zone?: string;
  isActive: boolean;
  time_slots_info: TimeSlotsInfo[];
};

export type TAvailableCapabilityFormData = {
  date: DateTime;
  total_capacity: number;
  isActive: boolean;
  time_slots_info: TimeSlotsInfo;
};

export type TCancelationDetails = {
  canCancel: boolean;
  message: string;
};

export type TScheduleActive = {
  id: string;
  schedule_date: string;
  type_service: string;
  schedule_time: TSlot;
  cancelationDetails: TCancelationDetails;
};

/** @deprecated */
export type TAppointment = {
  ownAccount: string;
  address: string;
  phone: string;
  account: string;
  city: string;
  vantimax_coverage: boolean;
  segmentedZone: string;
  scheduleActive: TScheduleActive[];
  availableCapabilities: TAvailableCapability[];
};

export type TScheduleDateForm = {
  journeyControl: string;
  date: DateTime;
  serviceSchedule: string;
};

export type TCreateScheduleFormData = {
  value: TScheduleDateForm;
  isValid: boolean;
};
