import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import { provideNgxMask } from 'ngx-mask';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { routes } from './app.routes';
import { apiInterceptor, authInterceptor } from './shared/interceptors';
import {
  provideRecaptchaV3,
  provideRecaptchaOptions,
  provideApollo,
} from 'src/config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([apiInterceptor, authInterceptor])),
    provideAnimations(),
    provideToastr(),
    provideApollo(),
    Apollo,
    provideNgxMask(),
    provideCharts(withDefaultRegisterables()),
    provideRecaptchaV3(),
    provideRecaptchaOptions(),
  ],
};
