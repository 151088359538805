import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, filter, from, map, switchMap, take } from 'rxjs';

import {
  AppDocumentService,
  NotifyService,
  SessionTimerService,
} from '@core/shared/services';
import { UserStore } from '@core/stores';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private readonly userStore = inject(UserStore);
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);
  private readonly notify = inject(NotifyService);
  private readonly sessionTimer = inject(SessionTimerService);
  private readonly location = inject(Location);
  private readonly appDoc = inject(AppDocumentService);

  clearStore() {
    this.userStore.onClear();
  }

  redirectTo(url: string) {
    return this.appDoc.replace(url);
  }

  goToInit() {
    this.router.navigate(['/scheduler'], { replaceUrl: true });
  }

  async onFailLogin(errMessage: string) {
    this.notify.error('Espera!', errMessage);
    await this.userStore.onClear();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  getTokenFromUrl() {
    return this.routerActive.queryParams.pipe(
      filter(({ token }) => !!token),
      map(({ token }) => token),
      switchMap((token) => from(this.userStore.getUserToken(token))),
    );
  }

  async doSignOut() {
    await this.userStore.doSignOut();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  closeSessionTimer() {
    let suscription: Subscription | null = null;

    this.location.onUrlChange((url) => {
      suscription?.unsubscribe();

      if (['/login'].includes(url)) {
        return;
      }

      suscription = this.sessionTimer
        .run()
        .pipe(take(1))
        .subscribe((event) => {
          this.notify.info('Fin de la sesión', event);
          this.doSignOut();
        });
    });
  }
}
