import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';

import {
  TAccountContract,
  TScheduleServiceOption,
} from '@core/domain/entities/scheduler/types';
import {
  LIST_CREATE_SCHEDULE_DATA,
  REQUEST_OTP_CODE,
  CONFIRM_OTP_CODE,
  SEARCH_ACCOUNT_DATA_CONTRACT,
} from '@core/modules/scheduler/data/gql';
import {
  TConfirmOTP,
  TGenerateOTPCode,
  TResponseData,
} from '@core/shared/types';
import { IAccountContractResponseData } from '@core/domain/entities/scheduler/interfaces';
import { environment } from '@env/environment';
import { Schedule } from '@core/shared/entities';
import { CreateSchedule } from '@core/domain/entities/scheduler';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly apiPath = `${environment.apiPath}/scheduler`;
  private readonly apolloClient = inject(Apollo);
  private readonly httpClient = inject(HttpClient);

  getAccountContractSchedules(contract: TAccountContract) {
    return this.apolloClient
      .query<{ validateAccountContractData: IAccountContractResponseData }>({
        query: SEARCH_ACCOUNT_DATA_CONTRACT,
        variables: { contract },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.validateAccountContractData),
      );
  }

  getCreateScheduleOptions() {
    return this.apolloClient
      .query<{ listAccountServiceType: TScheduleServiceOption[] }>({
        query: LIST_CREATE_SCHEDULE_DATA,
      })
      .pipe(
        take(1),
        map((resp) => resp.data.listAccountServiceType),
      );
  }

  generateOTPCode(phone: string) {
    return this.apolloClient
      .mutate<{ generateOTP: TGenerateOTPCode }>({
        mutation: REQUEST_OTP_CODE,
        variables: {
          body: {
            phone,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.generateOTP),
      );
  }

  confirmOTPCode(otp: string, phone: string) {
    return this.apolloClient
      .query<{ validateOTP: TConfirmOTP }>({
        query: CONFIRM_OTP_CODE,
        variables: {
          body: {
            otp,
            phone,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.validateOTP),
      );
  }

  createSchedule(formData: CreateSchedule) {
    return this.httpClient
      .post<Schedule>(`${this.apiPath}`, formData)
      .pipe(take(1));
  }

  cancelSchedule(scheduleId: string) {
    return this.httpClient
      .delete<TResponseData>(`${this.apiPath}/${scheduleId}`, {})
      .pipe(take(1));
  }
}
