import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClientOptions, ApolloLink } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { environment } from '@env/environment';

export const provideApollo = () => ({
  provide: APOLLO_OPTIONS,
  useFactory: (httpLink: HttpLink): ApolloClientOptions<unknown> => ({
    link: ApolloLink.from([
      httpLink.create({
        uri: environment.servicesUrl,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
    ]),
    cache: new InMemoryCache(),
  }),
  deps: [HttpLink],
});
