import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

import { IOperation, IFilial } from '@core/domain/interfaces';
import { computed, inject } from '@angular/core';
import { StorageService } from '@core/shared/services';
import { toTitleCase } from '@core/domain/helpers';
import { TOption } from '@core/domain/types';

type TCommonState = {
  operations: IOperation[];
  filials: IFilial[];
};

const initialState: TCommonState = {
  operations: [],
  filials: [],
};

export const CommonStore = signalStore(
  { providedIn: 'root' },
  withHooks((store) => {
    const storage = inject(StorageService);

    return {
      onInit: async () => {
        const operations = await storage.getItem('operations');
        const filials = await storage.getItem('filials');
        patchState(store, { operations, filials });
      },
    };
  }),
  withState(initialState),
  withComputed((store) => ({
    filialOptions$: computed(() => {
      return store.filials().map<TOption>((f: IFilial) => ({
        value: f.code,
        label: toTitleCase(f.name),
      }));
    }),
    operationOptions$: computed(() => {
      return store.operations().map<TOption>((o: IOperation) => ({
        value: o.name,
        label: toTitleCase(o.label),
      }));
    }),
  })),
  withMethods((store, storageService = inject(StorageService)) => ({
    onSetOperations: async (operations: IOperation[]) => {
      await storageService.setItem('operations', operations);
      patchState(store, { operations });
    },
    onSetFilials: async (filials: IFilial[]) => {
      await storageService.setItem('filials', filials);
      patchState(store, { filials });
    },
  })),
);
