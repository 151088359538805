export class ResponseMap {
  static success<T>(data: T) {
    return {
      success: true,
      message: '',
      data,
    };
  }

  static failed(err: any, data = null) {
    return {
      success: false,
      message: (err?.error?.message || err?.message) as string,
      data,
    };
  }
}
