import { inject, Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { TFiltersData } from './types';
import { GET_APP_FILTERS_DATA } from './querys.gql';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private readonly apolloClient = inject(Apollo);

  getFiltersData(): Observable<TFiltersData> {
    return this.apolloClient
      .query<TFiltersData>({
        query: GET_APP_FILTERS_DATA,
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data),
      );
  }
}
