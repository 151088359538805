import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { environment } from '@env/environment';
import { AppDocumentService } from '../services';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const route = inject(ActivatedRoute);
  const document = inject(AppDocumentService);
  const pathName = document.pathName();
  const auxPath = pathName?.split('/');

  const headers: Record<string, string> = {
    'x-application': 'smart-mobility',
    'x-role': 'tecnico',
  };

  if (auxPath?.at(1) === 'public') {
    headers['x-key'] = environment.apiKeyPublicAccess;
    headers['x-user'] =
      route.snapshot.queryParams?.['x'] || environment.publicTypeUser.CUSTOMER;
  }

  const clonedRequest = req.clone({
    setHeaders: headers,
  });

  return next(clonedRequest);
};
