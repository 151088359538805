import { gql } from 'apollo-angular';

export const LIST_CREATE_SCHEDULE_DATA = gql`
  query Query {
    listAccountServiceType {
      id
      service_type
      status
    }
  }
`;

export const REQUEST_OTP_CODE = gql`
  mutation GenerateOTP($body: OtpPhoneDto!) {
    generateOTP(body: $body) {
      message
      status
      code
    }
  }
`;

export const CONFIRM_OTP_CODE = gql`
  query Query($body: OtpCodeDto!) {
    validateOTP(body: $body) {
      message
      status
      code
    }
  }
`;
