import { Component, computed, inject, OnInit } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, firstValueFrom, map, of } from 'rxjs';

import { NotifyService } from '@core/shared/services';
import { AuthFacade } from '@core/domain/facades';
import { UserStore } from '@core/stores';
import { ResponseMap } from '@core/domain/helpers';

@Component({
  selector: 'page-login',
  standalone: true,
  imports: [MatSelectModule, RecaptchaV3Module],
  templateUrl: './login.component.html',
  styleUrl: './login.styles.css',
})
export class LoginComponent implements OnInit {
  private readonly authFacade = inject(AuthFacade);
  private readonly userStore = inject(UserStore);
  private readonly notify = inject(NotifyService);
  private readonly recaptchaService = inject(ReCaptchaV3Service);

  private readonly recaptchaAction = 'init_form_signin';

  public readonly isFetching$ = computed(() => this.userStore.isFetching());

  ngOnInit() {
    this.authFacade.clearStore();
  }

  async getRecaptcha() {
    const captcha = await firstValueFrom(
      this.recaptchaService.execute(this.recaptchaAction).pipe(
        map(ResponseMap.success),
        catchError((err) => of(ResponseMap.failed(err))),
      ),
    );

    if (!captcha.success) {
      this.notify.error('Espera', captcha.message);
      return '';
    }

    return captcha.data;
  }

  async onSignIn() {
    const captcha = await this.getRecaptcha();

    if (!captcha) {
      return;
    }

    const resp = await this.userStore.getUrlAuth(captcha, this.recaptchaAction);

    if (!resp.success) {
      this.notify.error('Espera', resp.message);
      return;
    }

    this.authFacade.redirectTo(resp.data);
  }
}
