/* eslint-disable no-empty-function */
import { DateTime } from 'luxon';

import {
  TAppointment,
  TAvailableCapability,
  TimeSlotsInfo,
  TScheduleActive,
} from './types';
import { DateTimeAdapter } from '@core/domain/adapters';
import { IAccountContractResponseData } from './interfaces';

export class ErrorAccount {
  private constructor(
    readonly message: string,
    readonly status: 'SUCCESS' | 'ERROR',
    readonly errorCode?: number,
  ) {}

  static fromJson(contract: Partial<IAccountContractResponseData>) {
    return new ErrorAccount(
      contract?.message || '',
      contract?.status || 'ERROR',
      contract?.errorCode || 0,
    );
  }

  get hasError() {
    return this.status === 'ERROR';
  }
}

export class AccountInfo {
  private constructor(
    readonly userName: string,
    readonly address: string,
    readonly phone: string,
    readonly account: string,
    readonly city: string,
    readonly hasVantiMax: boolean,
    readonly segmentedZone: string,
  ) {}

  static fromJson(appointment: Partial<TAppointment>) {
    return new AccountInfo(
      appointment?.ownAccount || '',
      appointment?.address || '',
      appointment?.phone || '',
      appointment?.account || '',
      appointment?.city || '',
      appointment?.vantimax_coverage || false,
      appointment?.segmentedZone || '0',
    );
  }

  get accountNumber() {
    return this.account.replace('0000', '');
  }

  isSegmentedZone() {
    return this.segmentedZone?.at(-1) !== '0';
  }
}

export class ScheduleActiveEntity {
  private constructor(
    readonly id: string,
    readonly scheduleDate: DateTime,
    readonly typeService: string,
    readonly scheduleTime: string,
    readonly canCancel: boolean,
    readonly message: string,
  ) {}

  static fromJson(account: Partial<TScheduleActive>) {
    return new ScheduleActiveEntity(
      account?.id || '',
      account?.schedule_date
        ? DateTimeAdapter.fromISO(account.schedule_date?.toString())
        : null,
      account?.type_service || '',
      account?.schedule_time?.toLocaleUpperCase() || '',
      account?.cancelationDetails?.canCancel || false,
      account?.cancelationDetails?.message || '',
    );
  }
}

export class AvailableCapabilityEntity {
  private constructor(
    readonly date: DateTime,
    readonly totalCapacity: number,
    readonly segmentedZone: string,
    readonly isActive: boolean,
    readonly timeSlotsInfo: TimeSlotsInfo[],
  ) {}

  static fromJson(available: Partial<TAvailableCapability>) {
    return new AvailableCapabilityEntity(
      available?.date
        ? DateTimeAdapter.fromISO(available?.date?.toString())
        : null,
      available?.total_capacity || 0,
      available?.segmented_zone || '',
      available?.isActive || false,
      available?.time_slots_info?.map((timeslot) => ({
        capacity: timeslot?.capacity || 0,
        slot: timeslot?.slot || 'AM',
        label: timeslot?.label || '',
      })) || [],
    );
  }
}

export function scheduleActiveListEntity(schedule: Partial<TScheduleActive>[]) {
  return schedule?.map((item) => ScheduleActiveEntity.fromJson(item)) || [];
}

export function availableCapacityListEntity(
  available: Partial<TAvailableCapability>[],
) {
  return (
    available?.map((item) => AvailableCapabilityEntity.fromJson(item)) || []
  );
}
