import { toTitleCase } from '@core/domain/helpers';
import { ICapabilitiesDays, TCapabilityType } from '../types';

export const mapCapabilityDayList = (
  capabilities: Partial<ICapabilitiesDays>[],
) => capabilities.map((cap) => CapabilityDay.fromJson(cap));

export class CapabilityDay {
  readonly capabilityId: number | null;
  readonly filialCapabilityId: number;
  readonly zoneId: string;
  readonly zoneName: string;
  readonly segmentedZoneId: string;
  readonly segmentedZoneName: string;
  readonly initialCapability: number;
  readonly available: number;
  readonly scheduled: number;
  readonly availableAm: number;
  readonly availablePm: number;
  readonly operationLabel: string;
  readonly capabilityType: TCapabilityType;
  readonly active: boolean;
  readonly isDefault: boolean;

  private constructor(capability: ICapabilitiesDays, isDefault: boolean) {
    this.capabilityId = capability.capability_id;
    this.filialCapabilityId = capability.filial_capability_id;
    this.zoneId = capability.zone_id;
    this.zoneName = capability.zone_name;
    this.segmentedZoneId = capability.segmented_zone_id;
    this.segmentedZoneName = capability.segmented_zone_name;
    this.initialCapability = capability.initial_capability;
    this.available = capability.total_available;
    this.scheduled = capability.total_scheduled;
    this.availableAm = capability.available_am;
    this.availablePm = capability.available_pm;
    this.operationLabel = capability.operation_label;
    this.capabilityType = capability.capability_type;
    this.active = capability.active;
    this.isDefault = isDefault;
  }

  static fromJson(capability: Partial<ICapabilitiesDays> = {}) {
    const isDefault = !Object.keys(capability)?.length;

    return new CapabilityDay(
      {
        capability_id: capability?.capability_id ?? null,
        filial_capability_id: capability?.filial_capability_id ?? 0,
        zone_id: capability?.zone_id ?? '',
        zone_name: toTitleCase(capability?.zone_name ?? ''),
        segmented_zone_id: capability?.segmented_zone_id || capability?.zone_id,
        segmented_zone_name: toTitleCase(
          capability?.segmented_zone_name || capability?.zone_name,
        ),
        initial_capability: capability?.initial_capability ?? 0,
        total_available: capability?.total_available ?? 0,
        total_scheduled: capability?.total_scheduled ?? 0,
        available_am: capability?.available_am ?? 0,
        available_pm: capability?.available_pm ?? 0,
        operation_label: toTitleCase(capability?.operation_label ?? ''),
        capability_type: capability?.capability_type ?? 'NO_SEGMENTED',
        active: capability?.active ?? false,
      },
      isDefault,
    );
  }

  get isSegmentedZone() {
    return this.capabilityType === 'SEGMENTED_ZONE';
  }
}
