import { Component, OnInit, inject } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';

import { AuthFacade, UIFacade } from '@core/domain/facades';
import { filialBuilder, operationBuilder } from '@core/domain/builders';

@Component({
  selector: 'page-auth-verify',
  standalone: true,
  imports: [],
  templateUrl: './auth-verify.component.html',
})
export class AuthVerifyComponent implements OnInit {
  private readonly authFacade = inject(AuthFacade);
  private readonly uiFacade = inject(UIFacade);

  ngOnInit(): void {
    this.authFacade
      .getTokenFromUrl()
      .pipe(
        switchMap(() =>
          this.uiFacade.getAppFilters().pipe(
            map((resp) => ({
              success: true,
              message: '',
              data: {
                operations: resp.operations.map(operationBuilder),
                filials: resp.filials.map(filialBuilder),
              },
            })),
          ),
        ),
        catchError((err) =>
          of({
            success: false,
            message: err.message as string,
            data: null,
          }),
        ),
      )
      .subscribe({
        next: ({ success, data, message }) => {
          if (!success) {
            this.authFacade.onFailLogin(message);
            return;
          }

          this.uiFacade.storeAppFilters(data);
          this.authFacade.goToInit();
        },
      });
  }
}
