import { IFilial, IOperation } from '../interfaces';

export function operationBuilder(operation: Partial<IOperation>): IOperation {
  return {
    id: operation?.id || '',
    label: operation?.label || '',
    name: operation?.name || '',
  };
}

export function filialBuilder(filial: Partial<IFilial>): IFilial {
  return {
    id: filial?.id || '',
    code: filial?.code || '',
    name: filial?.name || '',
  };
}
